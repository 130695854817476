<template>
  <div class="helpfriend" @click="handleClick">
    <div class="mask"></div>
    <div class="helpfriend_boxs">
      <img
        src="https://src.999yes.com/mobileimages/gh/en/crowdfunding/pinduoduo_18.png"
        alt=""
      />
      <div class="close_btn">
        <img src="@/assets/images/common/pinduoduo_20.png" alt="" />
      </div>
      <!--  <div class="helpfriend_title">
        {{ this.$t('FRIENDEARN') }}{{ reslutAmount }}
      </div> -->
      <div class="helpfriend_content">
        <div>{{ this.$t('AfriendPhone') }}{{ countryPhone }} {{ number }}</div>
        <div>
          {{ this.$t('invitedyou') }}
          <span>{{ this.$t('KSH') }}{{ reslutAmount }}</span>
          {{ this.$t('helphim') }}
          <span>{{ this.$t('KSH') }}{{ reslutAmount }}</span>
        </div>
        <div class="helpfriend_content_footer">
          {{ this.$t('aftersign') }}
          <span>{{ this.$t('KSH') }}{{ reslutAmount }}</span>
        </div>
        <div class="helpfriend_btn">
          {{ this.$t('Helpfriend') }} {{ this.$t('KSH') }}{{ reslutAmount }}
        </div>
        <!--  <div class="helpfriend_footer">
          {{ this.$t('Afterregistering') }}{{ reslutAmount }}
        </div> -->
      </div>
    </div>
  </div>
</template>
        <script>
export default {
  data() {
    return {
      reslutAmount: 99,
      number: '99',
      guid: 1000055,
      countdown: 10,
      countryPhone: '+254 ',
      countryData: [
        {
          country: 'ke',
          phone: '+254'
        },
        {
          country: 'gh',
          phone: '+233'
        },
        {
          country: 'zm',
          phone: '+260'
        },
        {
          country: 'br',
          phone: '+55'
        },
        {
          country: 'ph',
          phone: '+63'
        },
        {
          country: 'ug',
          phone: '+256'
        },
        {
          country: 'ng',
          phone: ' '
        },
        {
          country: 'cm',
          phone: '+237'
        },
        {
          country: 'tz',
          phone: '+255'
        },
        {
          country: 'ic',
          phone: '+225'
        }
      ]
    }
  },
  methods: {
    getRandomArbitrary(min, max) {
      return (Math.random() * (max - min) + min).toFixed(2)
    },
    getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(this.getRandomArbitrary(min, max))
    },
    handleClick() {
      location.href =
        window.$JumpUrl +
        `${this.country}/Crowdfunding/?guid=${this.guid}&key=friend`
    },
    maskNumber(number, len) {
      let str = number.toString()
      const lastLen = -Number(str.length - len - 4 - 3)
      let firstPart = str.slice(0, 3)
      let lastPart = str.slice(lastLen)
      let masked = firstPart + '****' + lastPart
      return masked
    },
    getPddMessage(t) {
      const that = t
      let xhr = new XMLHttpRequest()
      xhr.open('POST', `https://yxgh.999yes.com/pdd?guid=${that.guid}`, true)
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && (xhr.status == 200 || xhr.status == 304)) {
          let res = JSON.parse(xhr.responseText)
          if (res.code === 0) {
            that.reslutAmount = res.reward / 10000
            that.number = that.maskNumber(res.account, res.account.length)
            that.$parent.countdownHours = res.continueTime
            that.$parent.topAmount = that.reslutAmount
            that.$parent.amountObtained = (
              (that.reslutAmount * that.getRandomArbitrary(97, 99)) /
              100
            ).toFixed(2)
          }
        }
      }
      xhr.send()
    }
  },
  mounted() {
    const pathname = window.location.pathname.split('/')
    for (const iterator of this.countryData) {
      if (iterator.country === pathname[1]) {
        this.countryPhone = iterator.phone
      }
    }
  },
  created() {
    const url = window.location.href
    const urlParams = new URLSearchParams(new URL(url).search)
    const newStr = urlParams.get('guid')
    this.guid = newStr
    const pathname = new URL(url).pathname
    const parts = pathname.split('/')
    this.country = parts[1]
    this.getPddMessage(this)
  }
}
</script>
        <style lang="less" scoped>
.helpfriend {
  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    animation: mask 0.4s;
  }
  .helpfriend_boxs {
    overflow: hidden;
    position: fixed;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: helpfriend 0.4s;
    border-radius: 12px;
    img {
      width: 652px;
      height: 631px;
    }
    .close_btn {
      position: absolute;
      top: 70px;
      right: 25px;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .helpfriend_title {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 220px;
      background: linear-gradient(to top, #f8d96a, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 800;
    }
    .helpfriend_content {
      padding: 0px 50px;
      position: absolute;
      top: 190px;
      font-size: 28px;
      color: #252525;
      span {
        font-size: 32px;
        font-weight: bold;
        color: #cd0000;
      }
      .userMsg {
        color: #fff;
      }
      .helpfriend_content_footer {
        color: #b20000;
      }
    }
    .helpfriend_btn {
      margin: 0 auto;
      width: 494px;
      height: 86px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 5px;
      background: linear-gradient(to bottom, #f75f00, #f73301);
      color: #fbfcfb;
      font-size: 32px;
      border-radius: 16px;
      font-weight: bold;
      border-radius: 45px;
      text-shadow: -1px -2px 4px #9e330f, 1px -2px 4px #9e330f,
        -1px 2px 4px #9e330f, 1px 2px 4px #9e330f;
      margin-top: 20px;
      box-shadow: 4px 10px 8px rgba(0, 0, 0, 0.4), -2px 0 4px rgba(0, 0, 0, 0.2),
        0 2px 4px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0.2);
    }
    .helpfriend_footer {
      line-height: 36px;
      margin-top: 30px;
      color: #41c761;
      text-decoration: underline;
    }
  }
}

@keyframes helpfriend {
  0% {
    scale: 0.8;
  }
  100% {
    scale: 1;
  }
}
@keyframes mask {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
  